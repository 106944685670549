// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_th d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_tj d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_tk d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_tl d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_tm d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_tn d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_tp d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_rt q_rt";
export var heroExceptionNormal = "w_rv q_rv";
export var heroExceptionLarge = "w_rw q_rw";
export var Title1Small = "w_q8 q_q8 q_qF q_qG";
export var Title1Normal = "w_q9 q_q9 q_qF q_qH";
export var Title1Large = "w_rb q_rb q_qF q_qJ";
export var BodySmall = "w_rn q_rn q_qF q_qY";
export var BodyNormal = "w_rp q_rp q_qF q_qZ";
export var BodyLarge = "w_rq q_rq q_qF q_q0";