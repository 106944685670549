// extracted by mini-css-extract-plugin
export var alignDiscLeft = "v_sR d_fp d_bG d_dv";
export var alignLeft = "v_qf d_fp d_bG d_dv";
export var alignDiscCenter = "v_sS d_fq d_bD d_dw";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignDiscRight = "v_sT d_fr d_bH d_dx";
export var alignRight = "v_qg d_fr d_bH d_dx";
export var footerContainer = "v_sV d_dW d_bW";
export var footerContainerFull = "v_sW d_dT d_bW";
export var footerHeader = "v_kf d_kf";
export var footerTextWrapper = "v_sX d_w";
export var footerDisclaimerWrapper = "v_km d_km d_ck";
export var badgeWrapper = "v_sY d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "v_sZ d_bz d_bJ d_bN d_bL";
export var wide = "v_s0 d_cy";
export var right = "v_s1 d_bK";
export var line = "v_fk d_fl d_cv";
export var badgeDisclaimer = "v_s2 d_bC d_bP d_bJ";
export var badgeContainer = "v_s3 d_bz d_bH d_bP";
export var badge = "v_s4";
export var padding = "v_s5 d_c7";
export var end = "v_s6 d_bH";
export var linkWrapper = "v_s7 d_dB";
export var link = "v_nv d_dB";
export var colWrapper = "v_s8 d_cx";
export var consent = "v_f d_f d_bC d_bP";
export var disclaimer = "v_s9 d_bz d_bJ";
export var media = "v_tb d_bx d_dy";
export var itemRight = "v_tc";
export var itemLeft = "v_td";
export var itemCenter = "v_tf";
export var exceptionWeight = "v_tg q_q5";