// extracted by mini-css-extract-plugin
export var articleWrapper = "l_py";
export var articleText = "l_pz d_dv d_cs";
export var header = "l_pB d_Z d_w";
export var headerImageWrapper = "l_pC d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "l_pD d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "l_pF d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "l_pG d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "l_pH d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "l_nn d_w";
export var dateTag = "l_pJ d_cf d_cr d_dq d_w";
export var icon1 = "l_pK d_cP";
export var icon2 = "l_pL d_cP d_cJ";
export var tagBtn = "l_pM d_b1 d_cD d_dp d_dB";
export var headerText = "l_pN d_w d_cf d_cs d_bW";
export var center = "l_pP d_dw";
export var videoIframeStyle = "l_pQ d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "l_pR d_cs d_Z";
export var articleImageWrapperIcon = "l_pS d_cs d_Z";
export var articleRow = "l_pT d_bD";
export var quoteWrapper = "l_gQ d_bC d_bP d_cs";
export var quoteBar = "l_pV d_H";
export var quoteText = "l_pW";
export var authorBox = "l_pX d_w";
export var authorRow = "l_pY d_bD d_bW d_cy";
export var separator = "l_pZ d_w";
export var line = "l_fk d_w d_fk d_cY d_cy";
export var authorImage = "l_ln d_b7 d_Z d_w d_ct";
export var authorText = "l_p0 d_ch d_ct";
export var masonryImageWrapper = "l_p1";
export var bottomSeparator = "l_p2 d_w d_cy";
export var linksWrapper = "l_p3 d_ch d_dv";
export var comments = "l_p4 d_bD d_cy";
export var sharing = "l_p5 d_bz d_bD d_bJ";
export var shareText = "l_p6 d_w d_dw";
export var icon = "l_p7";
export var customRow = "l_p8 d_bD d_Z";
export var text = "l_p9";
export var SubtitleSmall = "l_qb";
export var SubtitleNormal = "l_qc";
export var SubtitleLarge = "l_qd";